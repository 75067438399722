import { getObjectStorageData } from '@/components/homes/fileList/function/objectStorageFn'
import { newSymbolName } from '@/utils/graphic_schema/layout/layout-util.js'
import {
  getUserProjectList_api, //获取个人用户项目目录
  getFileList_api, //获取文件列表
  getTeamProjectList_api, //获取团队项目列表
  getTeamPermissionList_api,
  noPermissionFile_api,
} from '@/api/file/file.js'
import { projectCopyTo, fileCopyTo } from '@/components/homes/fileList/function/fileListPublicFn'
import { normalFileCopyTo } from '@/components/homes/fileList/function/fileListApiFn'

const rename = function (name, name_list) {
  if (name_list.some(item_name => item_name == name)) {
    let r1 = /\[(.+?)\]/g
    let r2 = /\((.+?)\)/g
    let r3 = /\（(.+?)\）/g
    if (name.match(r1)) {
      let name_pre = name.split(name.match(r1)[0])[0]
      let current_index = Number(name.match(r1)[0].split('[')[1].split(']')[0])
      let current_new_name = `${name_pre}(${current_index + 1})`
      let new_name = rename(current_new_name, name_list)
      return new_name
    } else if (name.match(r2)) {
      let name_pre = name.split(name.match(r2)[0])[0]
      let current_index = Number(name.match(r2)[0].split('(')[1].split(')')[0])
      let current_new_name = `${name_pre}(${current_index + 1})`
      let new_name = rename(current_new_name, name_list)
      return new_name
    } else if (name.match(r3)) {
      let name_pre = name.split(name.match(r3)[0])[0]
      let current_index = Number(name.match(r3)[0].split('（')[1].split('）')[0])
      let current_new_name = `${name_pre}(${current_index + 1})`
      let new_name = rename(current_new_name, name_list)
      return new_name
    } else {
      let current_new_name = `${name}(1)`
      let new_name = rename(current_new_name, name_list)
      return new_name
    }
  } else {
    return name
  }
}

export const batchProjectCopyTo = async function (select_project_list, snow_team_id, acesserType, file_type) {
  let team_gns = `gns://${snow_team_id}`
  let all_project_data = []
  if (snow_team_id !== '') {
    all_project_data = await getTeamProjectList_api({
      start: 0,
      rows: 100,
      snow_team_id: snow_team_id,
      team_gns: team_gns,
      key_word: '',
    })
  } else {
    all_project_data = await getUserProjectList_api({
      start: 0,
      rows: 50,
    })
  }
  let all_project_name = all_project_data.data.data.map(item => {
    return item._source.name
  })

  try {
    for (let i = 0; i < select_project_list.length; i++) {
      let project_gns = select_project_list[i].gns
      let project_snow_id = project_gns.split('/')[project_gns.split('/').length - 1]
      let targetNode = {}
      let new_name = rename(select_project_list[i].name, all_project_name)
      targetNode.form_copyName = new_name
      targetNode.node_data = {
        data: {
          snow_id: snow_team_id,
          gns: snow_team_id !== '' ? team_gns : '',
          isTeam: acesserType == 1 ? false : true,
        },
        childNodes: all_project_data.data.data.map(item => {
          let obj = {}
          obj.data = item._source
          return obj
        }),
      }
      let all_file_list = await getFileList_api({
        start: 0,
        rows: 100,
        parent_gns: project_gns,
        category: '文件',
        category_child: file_type == 1 ? '个人文件' : '团队文件',
      })
      let currentNode = getProjectNode(project_gns, project_snow_id, select_project_list[i].name, file_type)
      currentNode.parent = {
        data: {
          snow_id: snow_team_id,
          isTeam: file_type == 1 ? false : true,
          gns: `gns://${snow_team_id}`,
        },
      }
      currentNode.childNodes = all_file_list.data.data.map(item => {
        item._source.file_type = item._source.types
        let obj = {}
        obj.data = item._source
        obj.data.isTeam = file_type == 1 ? false : true
        obj.parent = currentNode
        return obj
      })
      for (let j = 0; j < currentNode.childNodes.length; j++) {
        let fileNode = currentNode.childNodes[j]
        let all_cell_list = await getFileList_api({
          start: 0,
          rows: 100,
          parent_gns: fileNode.data.gns,
          category: 'CELL',
          category_child: file_type == 1 ? '个人cell' : '团队cell',
        })
        fileNode.childNodes = all_cell_list.data.data.map(item => {
          item._source.cell_type = item._source.types
          let obj = {}
          obj.data = item._source
          obj.data.isTeam = file_type == 1 ? false : true
          obj.parent = fileNode
          return obj
        })
      }
      // let all_data = {
      //   project_list: [],
      //   file_list: [],
      //   cell_list: [],
      //   snow_team_id: '',
      //   accessor_type: '',
      // }
      await projectCopyTo(currentNode, targetNode)
      // let data = await projectCopyTo(currentNode, targetNode)
      // all_data.snow_team_id = targetNode.node_data.data.snow_id
      // all_data.accessor_type = targetNode.node_data.data.isTeam ? 2 : 1
      // all_data.cell_list = data[2]
      // await normalFileCopyTo(all_data)
    }
  } catch (e) {
    return false
  }
  return true
}

export const batchFileCopyTo = async function (select_file_list, team_project_gns, snow_team_id, acesserType, file_type) {
  let all_file_list = await getFileList_api({
    start: 0,
    rows: 100,
    parent_gns: team_project_gns,
    category: '文件',
    category_child: acesserType == 1 ? '个人文件' : '团队文件',
  })
  let all_project_name = all_file_list.data.data.map(item => {
    return item._source.name
  })
  try {
    for (let i = 0; i < select_file_list.length; i++) {
      let new_name = rename(select_file_list[i].name, all_project_name)
      let targetNode = {}
      targetNode.form_copyName = new_name
      targetNode.node_data = {
        data: {
          snow_id: team_project_gns.split('/')[team_project_gns.split('/').length - 1],
          gns: team_project_gns,
          isTeam: acesserType == 1 ? false : true,
        },
        childNodes: all_file_list.data.data.map(item => {
          item._source.file_type = item._source.types
          let obj = {}
          obj.data = item._source
          return obj
        }),
        parent: {
          data: {
            snow_id: snow_team_id,
            isTeam: acesserType == 1 ? false : true,
            gns: `gns://${snow_team_id}`,
          },
        },
      }
      let fileNode = {
        data: select_file_list[i],
        childNodes: [],
      }
      fileNode.data.file_type = fileNode.data.types
      fileNode.parent = {
        data: {
          isTeam: file_type == 1 ? false : true,
        },
        childNodes: [],
      }
      let all_cell_list = await getFileList_api({
        start: 0,
        rows: 100,
        parent_gns: fileNode.data.gns,
        category: 'CELL',
        category_child: file_type == 1 ? '个人cell' : '团队cell',
      })
      fileNode.childNodes = all_cell_list.data.data.map(item => {
        item._source.cell_type = item._source.types
        let obj = {}
        obj.data = item._source
        obj.parent = fileNode
        return obj
      })
      // let all_data = {
      //   project_list: [],
      //   file_list: [],
      //   cell_list: [],
      //   snow_team_id: '',
      //   accessor_type: '',
      // }
      await fileCopyTo(fileNode, targetNode)
      // let data = await fileCopyTo(fileNode, targetNode)

      // all_data.snow_team_id = targetNode.node_data.parent.data.snow_id
      // all_data.accessor_type = targetNode.node_data.parent.data.isTeam ? 2 : 1
      // all_data.cell_list = data[1]
      // await normalFileCopyTo(all_data)
    }
  } catch (e) {
    return false
  }
  return true
}

export const projectShareTo = async function (select_project_list, snow_team_id, acesserType, file_type) {
  let team_gns = `gns://${snow_team_id}`
  let all_project_data = []
  if (snow_team_id !== '') {
    all_project_data = await getTeamProjectList_api({
      start: 0,
      rows: 100,
      snow_team_id: snow_team_id,
      team_gns: team_gns,
      key_word: '',
    })
  } else {
    all_project_data = await getUserProjectList_api({
      start: 0,
      rows: 50,
    })
  }
  try {
    for (let i = 0; i < select_project_list.length; i++) {
      let project_gns = select_project_list[i].gns
      let project_snow_id = project_gns.split('/')[project_gns.split('/').length - 1]
      let targetNode = {}
      targetNode.form_copyName = select_project_list[i].name
      targetNode.node_data = {
        data: {
          snow_id: snow_team_id,
          gns: snow_team_id !== '' ? team_gns : '',
          isTeam: acesserType == 1 ? false : true,
        },
        childNodes: all_project_data.data.data.map(item => {
          let obj = {}
          obj.data = item._source
          return obj
        }),
      }
      let all_file_list = await noPermissionFile_api({
        start: 0,
        rows: 100,
        parent_gns: project_gns,
        category: '文件',
        category_child: file_type == 1 ? '个人文件' : '团队文件',
      })
      let currentNode = getProjectNode(project_gns, project_snow_id, select_project_list[i].name, file_type)
      currentNode.parent = {
        data: {
          snow_id: snow_team_id,
          isTeam: file_type == 1 ? false : true,
          gns: `gns://${snow_team_id}`,
        },
      }
      currentNode.childNodes = all_file_list.data.data.map(item => {
        item._source.file_type = item._source.types
        let obj = {}
        obj.data = item._source
        obj.parent = currentNode
        return obj
      })
      for (let j = 0; j < currentNode.childNodes.length; j++) {
        let fileNode = currentNode.childNodes[j]
        let all_cell_list = await noPermissionFile_api({
          start: 0,
          rows: 100,
          parent_gns: fileNode.data.gns,
          category: 'CELL',
          category_child: file_type == 1 ? '个人cell' : '团队cell',
        })
        fileNode.childNodes = all_cell_list.data.data.map(item => {
          item._source.cell_type = item._source.types
          let obj = {}
          obj.data = item._source
          obj.parent = fileNode
          return obj
        })
      }

      // let all_data = {
      //   project_list: [],
      //   file_list: [],
      //   cell_list: [],
      //   snow_team_id: '',
      //   accessor_type: '',
      // }
      await projectCopyTo(currentNode, targetNode)
      // let data = await projectCopyTo(currentNode, targetNode)
      // all_data.snow_team_id = targetNode.node_data.data.snow_id
      // all_data.accessor_type = targetNode.node_data.data.isTeam ? 2 : 1
      // all_data.cell_list = data[2]

      // await normalFileCopyTo(all_data)
    }
  } catch (e) {
    return false
  }
  return true
}

export const fileShareTo = async function (select_file_list, project_gns, snow_team_id, acesserType, file_type) {
  let all_file_list = await getFileList_api({
    start: 0,
    rows: 100,
    parent_gns: project_gns,
    category: '文件',
    category_child: acesserType == 1 ? '个人文件' : '团队文件',
  })
  try {
    for (let i = 0; i < select_file_list.length; i++) {
      let targetNode = {}
      targetNode.form_copyName = select_file_list[i].name
      targetNode.node_data = {
        data: {
          snow_id: project_gns.split('/')[project_gns.split('/').length - 1],
          gns: project_gns,
          isTeam: acesserType == 1 ? false : true,
        },
        childNodes: all_file_list.data.data.map(item => {
          item._source.file_type = item._source.types
          let obj = {}
          obj.data = item._source
          return obj
        }),
        parent: {
          data: {
            snow_id: snow_team_id,
            isTeam: acesserType == 1 ? false : true,
            gns: `gns://${snow_team_id}`,
          },
        },
      }
      let fileNode = {
        data: select_file_list[i],
        childNodes: [],
      }
      fileNode.data.file_type = fileNode.data.types
      fileNode.parent = {
        data: {
          isTeam: file_type == 1 ? false : true,
        },
        childNodes: [],
      }
      let all_cell_list = await noPermissionFile_api({
        start: 0,
        rows: 100,
        parent_gns: fileNode.data.gns,
        category: 'CELL',
        category_child: file_type == 1 ? '个人cell' : '团队cell',
      })
      fileNode.childNodes = all_cell_list.data.data.map(item => {
        item._source.cell_type = item._source.types

        let obj = {}
        obj.data = item._source
        obj.parent = fileNode
        return obj
      })
      // let all_data = {
      //   project_list: [],
      //   file_list: [],
      //   cell_list: [],
      //   snow_team_id: '',
      //   accessor_type: '',
      // }
      await fileCopyTo(fileNode, targetNode)
      // let data = await fileCopyTo(fileNode, targetNode)

      // all_data.snow_team_id = targetNode.node_data.parent.data.snow_id
      // all_data.accessor_type = targetNode.node_data.parent.data.isTeam ? 2 : 1
      // all_data.cell_list = data[1]
      // await normalFileCopyTo(all_data)
    }
  } catch (e) {
    return false
  }
  return true
}

export const getFileNode = async function (file_gns, file_snow_id) {
  let fileNode = {}

  // fileNode.data = {
  //   category,
  //   category_child,
  //   file_type,
  //   gns,
  //   isTeam,
  //   name,
  //   snow_id
  // };
  fileNode.childNodes = []
  return fileNode
}

export const getProjectNode = function (project_gns, project_snow_id, name, type) {
  let projectNode = {}
  projectNode.data = {
    category: '项目',
    category_child: type == 1 ? '个人项目' : '',
    file_type: type,
    gns: project_gns,
    snow_id: project_snow_id,
    name: name,
    isTeam: type == 1 ? false : true,
  }
  projectNode.childNodes = []
  return projectNode
}
